<template lang="pug">
  div.row.pb-5
    template(v-if="detail.user_id!==0")
      //- Headers
      div.col-12
        h5.mb-1 ID:{{ target_id }}&nbsp;&nbsp;{{ detail.setting.label }}
        p {{ detail.valid? "監視中":"停止中" }}
        div.input-group.input-group-sm.mb-2
          button.btn.btn-outline-secondary(type="button", @click="copy(detail.setting.url)") コピー
          a.input-group-text(style="color:#0d6efd;", :href="detail.setting.url", target="_blank", rel="noopener") {{ detail.setting.url }}
        div.input-group.input-group-sm
          button.btn.btn-outline-secondary(type="button", @click="copy(`${APP_BASE_URL}/history2?id=${target_id}`)") コピー
          span.input-group-text {{ APP_BASE_URL+'/history2?id='+target_id }}
      //- Operations
      div.col-12.my-3
        template(v-if="detail.valid")
          button.btn.btn-outline-danger.me-1(type="button", @click="pause") 停止する
          button.btn.btn-outline-danger.mx-1(type="button", @click="restart") 再起動する
          button.btn.btn-outline-danger.mx-1(type="button", @click="change_monitor", :disabled="detail.group_id") ノードを変更する
        template(v-else)
          button.btn.btn-outline-success.me-1(type="button", @click="resume") 再開する
          button.btn.btn-outline-success.mx-1(type="button", @click="resume_manual", :disabled="detail.group_id") ノードを指定して再開する
        button.btn.btn-outline-info.mx-1(type="button", @click="migrate", :disabled="detail.group_id") グループを移動する
      hr
      //- Detail
      div.col-12.setting-editor
        h5 Detail
        div.d-inline-block
          input.form-check-input#edit-mode(type="checkbox", v-model="edit_mode")
          label.form-label.ms-2(for="edit-mode") 編集モード
        div.d-inline-block.ms-3
          button.btn.btn-outline-danger(type="button", @click="post", :disabled="!edit_mode") Update
        div.row
          //- Basic
          div.col-12.col-md-3
            h6.text-muted.mb-0 Basic
            table.table.table-sm
              tbody
                tr
                  th ID
                  td {{ target_id }}
                tr
                  th User
                  td 
                    router-link(:to="{ path:'/user', query:{ id:detail.user_id } }") {{ detail.user_id }}
                tr
                  th No
                  td {{ detail.no }}
                tr
                  th Monitor
                  td {{ detail.node_id }}
                tr
                  th Group
                  td {{ detail.group_id }}
                tr
                  th Timing
                  td {{ detail.timing }}
                tr
                  th Time
                  td {{ detail.processing_time }}
                tr
                  th Created
                  td {{ detail.created | moment }}
                tr
                  th Updated
                  td {{ detail.updated | moment }}
                tr
                  th Checked
                  td {{ detail.checked | moment }}
                tr
                  th Changed
                  td {{ detail.changed | moment }}
            div.mt-4
              label.fw-bold UserAgent
              textarea.form-control.form-control-sm(v-model="detail.setting.user_agent", :class="detail.setting.user_agent!==detail_original.setting.user_agent? 'changed':''", :disabled="!edit_mode", rows="3")

          //- Setting
          div.col-12.col-md-5
            h6.text-muted.mb-0 Setting
            table.table.table-sm
              tbody
                tr
                  th Trigger
                  td
                    div.input-group.input-group-sm(v-for="ct,i in detail.setting.check_triggers")
                      select.form-select(v-model.number="detail.setting.check_triggers[i].TriggerType", disabled)
                        option(v-for="t,j in trigger_types", :value="j", :key="j") {{ t }}
                      input.form-control(type="number", v-model.number="detail.setting.check_triggers[i].Value", :class="detail.setting.check_triggers[i].Value!==detail_original.setting.check_triggers[i].Value? 'changed':''", :disabled="!edit_mode")
                tr
                  th Type
                  td
                    select.form-select.form-select-sm(type="number", v-model.number="detail.setting.site_type", :class="detail.setting.site_type!==detail_original.setting.site_type? 'changed':''", :disabled="!edit_mode")
                      option(v-for="o,index in site_types", :key="o", :value="index") {{ o }}
                tr
                  th Device
                  td
                    div.input-group.input-group-sm
                      span.input-group-text W
                      input.form-control(type="number", v-model.number="detail.setting.device.width", :class="detail.setting.device.width!==detail_original.setting.device.width? 'changed':''", :disabled="!edit_mode")
                      span.input-group-text H
                      input.form-control(type="number", v-model.number="detail.setting.device.height", :class="detail.setting.device.height!==detail_original.setting.device.height? 'changed':''", :disabled="!edit_mode")
                      span.input-group-text s
                      input.form-control(type="number", v-model.number="detail.setting.device.scale", :class="detail.setting.device.scale!==detail_original.setting.device.scale? 'changed':''", :disabled="!edit_mode")
                tr
                  th Timeout
                  td 
                    input.form-control.form-control-sm(type="number", v-model.number="detail.timeout", :class="detail.timeout!==detail_original.timeout? 'changed':''", :disabled="!edit_mode")
                tr
                  th WaitTime
                  td
                    input.form-control.form-control-sm(type="number", v-model.number="detail.waittime", :class="detail.waittime!==detail_original.waittime? 'changed':''", :disabled="!edit_mode")
                tr
                  th MailTarget
                    button.btn.btn-sm.btn-outline-secondary.mx-2.px-2(type="button", @click="detail.setting.mails.push('')", :disabled="!edit_mode") +
                  td(:class="JSON.stringify(detail.setting.mails)!==JSON.stringify(detail_original.setting.mails)? 'changed':''")
                    div.input-group.input-group-sm(v-for="m,i in detail.setting.mails")
                      input.form-control(type="email", v-model="detail.setting.mails[i]", :disabled="!edit_mode")
                      button.btn.btn-outline-secondary(type="button", @click="detail.setting.mails.splice(i,1)", :disabled="!edit_mode") X
                      
                tr
                  th LINENotify
                  td
                    input.form-control.form-control-sm(type="text", v-model.number="detail.setting.line_access_token", :class="detail.setting.line_access_token!==detail_original.setting.line_access_token? 'changed':''", :disabled="!edit_mode")
                tr
                  th ScreenShot
                  td(:class="detail.setting.screenshot!==detail_original.setting.screenshot? 'changed':''")
                    input.form-check-input.mx-1(type="checkbox", v-model="detail.setting.screenshot", :disabled="!edit_mode")
                tr
                  th RandomIP
                  td(:class="detail.setting.random_ip!==detail_original.setting.random_ip? 'changed':''")
                    input.form-check-input.mx-1(type="checkbox", v-model="detail.setting.random_ip", :disabled="!edit_mode")
                tr
                  th IgnoreSameInHistories
                  td
                    input.form-control.form-control-sm(type="number", v-model.number="detail.setting.ignore_same_in_histories", :class="detail.setting.ignore_same_in_histories!==detail_original.setting.ignore_same_in_histories? 'changed':''", :disabled="!edit_mode")
                tr
                  th JobStartAt(UTC)
                    button.btn.btn-sm.btn-outline-secondary.mx-2(v-if="detail.job_start_at", type="button", @click="detail.job_start_at=null", :disabled="!edit_mode") X
                    button.btn.btn-sm.btn-outline-secondary.mx-2(v-else, type="button", @click="let n=new Date(); detail.job_start_at={ year:n.getUTCFullYear(), month:n.getUTCMonth()+1, day:n.getUTCDate(), hour:n.getUTCHours(), minute:n.getUTCMinutes(), second:n.getUTCSeconds() }", :disabled="!edit_mode") +
                  td(:class="JSON.stringify(detail.job_start_at)!==JSON.stringify(detail_original.job_start_at)? 'changed':''")
                    template(v-if="detail.job_start_at")
                      div.input-group.input-group-sm.mb-2
                        input.form-control(type="number", v-model.number="detail.job_start_at.year", :disabled="!edit_mode")
                        span.input-group-text /
                        input.form-control(type="number", v-model.number="detail.job_start_at.month", min="1", max="12", :disabled="!edit_mode")
                        span.input-group-text /
                        input.form-control(type="number", v-model.number="detail.job_start_at.day", min="1", max="31", :disabled="!edit_mode")
                      div.input-group.input-group-sm
                        input.form-control(type="number", v-model.number="detail.job_start_at.hour", min="0", max="23", :disabled="!edit_mode")
                        span.input-group-text :
                        input.form-control(type="number", v-model.number="detail.job_start_at.minute", min="0", max="59", :disabled="!edit_mode")
                        span.input-group-text :
                        input.form-control(type="number", v-model.number="detail.job_start_at.second", min="0", max="59", :disabled="!edit_mode")
          //- Setting & Login
          div.col-12.col-md-4
            h6.text-muted.mb-0 Setting
            table.table.table-sm
              tbody
                tr
                  th DebugMode
                  td(:class="detail.debug_mode!==detail_original.debug_mode? 'changed':''")
                    input.form-check-input.mx-1(type="checkbox", v-model="detail.debug_mode", :disabled="!edit_mode")
                tr
                  th DisableLocaleOverride
                  td(:class="detail.disable_locale_override!==detail_original.disable_locale_override? 'changed':''")
                    input.form-check-input.mx-1(type="checkbox", v-model="detail.disable_locale_override", :disabled="!edit_mode")
                tr
                  th DisableImageBlock
                  td(:class="detail.disabled_image_block!==detail_original.disabled_image_block? 'changed':''")
                    input.form-check-input.mx-1(type="checkbox", v-model="detail.disabled_image_block", :disabled="!edit_mode")
                tr
                  th TimingFixed
                  td(:class="detail.timing_fixed!==detail_original.timing_fixed? 'changed':''")
                    input.form-check-input.mx-1(type="checkbox", v-model="detail.timing_fixed", :disabled="!edit_mode")
                tr
                  th IgnoreNoElementCount
                  td(:class="detail.ignore_element_not_found_count!==detail_original.ignore_element_not_found_count? 'changed':''")
                    input.form-control.form-control-sm(type="number", v-model.number="detail.ignore_element_not_found_count", :disabled="!edit_mode")
                tr
                  th MaxRunTime
                  td(:class="detail.max_run_time!==detail_original.max_run_time? 'changed':''")
                    input.form-control.form-control-sm(type="number", v-model.number="detail.max_run_time", :disabled="!edit_mode")
                tr
                  th RandomExceptList
                    button.btn.btn-sm.btn-outline-secondary.mx-2.px-2(type="button", @click="detail.random_except_list.push('')", :disabled="!edit_mode") +
                  td(:class="JSON.stringify(detail.random_except_list)!==JSON.stringify(detail_original.random_except_list)? 'changed':''")
                    div.input-group.input-group-sm(v-for="m,i in detail.random_except_list")
                      input.form-control(type="text", v-model="detail.random_except_list[i]", :disabled="!edit_mode")
                      button.btn.btn-outline-secondary(type="button", @click="detail.random_except_list.splice(i,1)", :disabled="!edit_mode") X
                tr
                  th StaticStatus
                  td
                    select.form-select.form-select-sm(type="number", v-model.number="detail.static_status", :class="detail.static_status!==detail_original.static_status? 'changed':''", :disabled="!edit_mode")
                      option(value="1") NotStarted
                      option(value="2") Skipped
                      option(value="3") NotApplicable
                      option(value="4", disabled) Applicable
                      option(value="5") NeedCheck
                      option(value="6") NoElement
                      option(value="7") Error
                tr
                  th Proxy
                  td(:class="detail.proxy!==detail_original.proxy? 'changed':''")
                    input.form-check-input.mx-1(type="checkbox", v-model="detail.proxy", :disabled="!edit_mode")
                tr
                  th Proxy_alt
                  td(:class="detail.proxy_alt!==detail_original.proxy_alt? 'changed':''")
                    input.form-control.form-control-sm(type="text", v-model="detail.proxy_alt", :disabled="!edit_mode")

            h6.text-muted.mb-0 Login
            span.text-muted(v-if="detail.login.type==='none'") ログイン設定はありません
            table.table.table-sm(v-else)
              tbody
                tr
                  th Type
                  td {{ detail.login.type }}
                tr
                  th URL
                  td {{ detail.login.url }}
                tr
                  th Cookie
                  td {{ detail.login.cookie }}
                tr
                  th Action
                  td {{ detail.login.action }}
          //- Actions
          div.col-12
            h6.text-muted.mb-0 Actions
            span.text-muted(v-if="detail._actions.length===0") アクションはありません
            div.table-responsive(v-else)
              table.table.table-sm.action-table.mb-0
                thead
                  tr
                    th(style="width: 125px;") PathType
                    th PathValue
                    th.text-center(style="width: 80px;") PathWait
                    th.text-center(style="width: 100px;") Type
                    th Value
                    th.text-center(style="width: 80px;") Skippable
                tbody
                  tr(v-for="action,i in detail._actions")
                    td(:class="detail._actions[i].path.type!=detail_original._actions[i].path.type? 'changed':''")
                      select.form-select.form-select-sm(style="width: fit-content;", v-model.number="detail._actions[i].path.type", :disabled="!edit_mode")
                        option(v-for="o,j in target_path_types", :value="j", :key="o") {{ o }}
                    td(:class="JSON.stringify(detail._actions[i].path.value)!=JSON.stringify(detail_original._actions[i].path.value)? 'changed':''")
                      div.input-group.input-group-sm.mb-1.my-1
                        button.btn.btn-outline-secondary(type="button", @click="copy(`document.querySelector('${detail._actions[i].path.value[0]}')`)") {{ detail._actions[i].path.value.length===1? 'Element':'Iframe' }}
                        input.form-control(type="text", v-model="detail._actions[i].path.value[0]", :disabled="!edit_mode")
                        button.btn.btn-outline-secondary(v-if="detail._actions[i].path.value.length===1", type="button", @click="detail._actions[i].path.value.push('')", :disabled="!edit_mode") +
                      div.input-group.input-group-sm.mb-1(v-if="detail._actions[i].path.value.length===2")
                        button.btn.btn-outline-secondary(type="button", @click="copy(`document.querySelector('${detail._actions[i].path.value[1]}')`)") Element
                        input.form-control(type="text", v-model="detail._actions[i].path.value[1]", :disabled="!edit_mode")
                        button.btn.btn-outline-secondary(type="button", @click="detail._actions[i].path.value.pop(1)", :disabled="!edit_mode") X
                    td(:class="detail._actions[i].path.wait_seconds!=detail_original._actions[i].path.wait_seconds? 'changed':''")
                      input.form-control.form-control-sm.mx-auto(style="width: 50px;", type="number", v-model.number="detail._actions[i].path.wait_seconds", :disabled="!edit_mode")
                    td(:class="detail._actions[i].type!=detail_original._actions[i].type? 'changed':''")
                      select.form-select.form-select-sm.mx-auto(style="width: fit-content;", v-model.number="detail._actions[i].type", :disabled="!edit_mode")
                        option click
                        option input
                        option key
                        option goto
                    td(:class="detail._actions[i].value!=detail_original._actions[i].value? 'changed':''")
                      input.form-control.form-control-sm(type="text", v-model="detail._actions[i].value", :disabled="!edit_mode")
                    td.text-center(:class="detail._actions[i].skippable!=detail_original._actions[i].skippable? 'changed':''")
                      input.form-check-input.mx-1(type="checkbox", v-model="detail._actions[i].skippable", :disabled="!edit_mode")

          //- TargetAreas
          div.col-12.mt-3
            h6.text-muted.mb-0 Target Areas
            div.table-responsive
              table.targetarea-table.table.table-sm.mb-0
                thead
                  tr
                    th ID/Label
                    th Path
                    th 
                    th
                tbody
                  tr(v-for="area,i in detail._areas")
                    td {{ area.id }}<br>{{ area.label }}
                    td(:class="JSON.stringify(detail._areas[i].path)!=JSON.stringify(detail_original._areas[i].path)? 'changed':''")
                      div.input-group.input-group-sm.mb-2.mt-2
                        span.input-group-text Type
                        select.form-select(v-model.number="detail._areas[i].path.type", :disabled="!edit_mode")
                          option(v-for="o,j in target_path_types", :value="j", :key="o") {{ o }}
                        span.input-group-text WaitTime
                        input.form-control(type="number", v-model.number="detail._areas[i].path.wait_seconds", :disabled="!edit_mode")
                      div.input-group.input-group-sm.mb-2
                        button.btn.btn-outline-secondary(type="button", @click="copy(`document.querySelector('${detail._areas[i].path.value[0]}')`)") {{ detail._areas[i].path.value.length===1? 'Element':'Iframe' }}
                        input.form-control(type="text", v-model="detail._areas[i].path.value[0]", :disabled="!edit_mode")
                        button.btn.btn-outline-secondary(v-if="detail._areas[i].path.value.length===1", type="button", @click="detail._areas[i].path.value.push('')", :disabled="!edit_mode") +
                      div.input-group.input-group-sm(v-if="detail._areas[i].path.value.length===2")
                        button.btn.btn-outline-secondary(type="button", @click="copy(`document.querySelector('${detail._areas[i].path.value[1]}')`)") Element
                        input.form-control(type="text", v-model="detail._areas[i].path.value[1]", :disabled="!edit_mode")
                        button.btn.btn-outline-secondary(type="button", @click="detail._areas[i].path.value.pop(1)", :disabled="!edit_mode") X
                    td.area-option
                      div.my-1(:class="detail._areas[i].option.remove_tag!==detail_original._areas[i].option.remove_tag? 'changed':''")
                        input.form-check-input.mx-1(:id="'remove_tag_'+i", type="checkbox", v-model="detail._areas[i].option.remove_tag", :disabled="!edit_mode")
                        label.ms-1.form-check-label(:for="'remove_tag_'+i") HTMLタグを除去する
                      div.mb-1(:class="detail._areas[i].option.ignore_date!==detail_original._areas[i].option.ignore_date? 'changed':''")
                        input.form-check-input.mx-1(:id="'ignore_date_'+i", type="checkbox", v-model="detail._areas[i].option.ignore_date", :disabled="!edit_mode")
                        label.ms-1.form-check-label(:for="'ignore_date_'+i") 日付を除外する
                      div.mb-1(:class="detail._areas[i].option.ignore_time!==detail_original._areas[i].option.ignore_time? 'changed':''")
                        input.form-check-input.mx-1(:id="'ignore_time_'+i", type="checkbox", v-model="detail._areas[i].option.ignore_time", :disabled="!edit_mode")
                        label.ms-1.form-check-label(:for="'ignore_time_'+i") 時刻を除外する
                      div.mb-1(:class="detail._areas[i].option.ignore_num!==detail_original._areas[i].option.ignore_num? 'changed':''")
                        input.form-check-input.mx-1(:id="'ignore_num_'+i", type="checkbox", v-model="detail._areas[i].option.ignore_num", :disabled="!edit_mode")
                        label.ms-1.form-check-label(:for="'ignore_num_'+i") 数字を除外する
                      div.mb-1(:class="detail._areas[i].option.ignore_deleted!==detail_original._areas[i].option.ignore_deleted? 'changed':''")
                        input.form-check-input.mx-1(:id="'ignore_deleted_'+i", type="checkbox", v-model="detail._areas[i].option.ignore_deleted", :disabled="!edit_mode")
                        label.ms-1.form-check-label(:for="'ignore_deleted_'+i") 追加分のみをチェックする
                      div.mb-1(:class="detail._areas[i].option.allow_same_words!==detail_original._areas[i].option.allow_same_words? 'changed':''")
                        input.form-check-input.mx-1(:id="'ignore_allow_same_'+i", type="checkbox", v-model="detail._areas[i].option.allow_same_words", :disabled="!edit_mode")
                        label.ms-1.form-check-label(:for="'ignore_allow_same_'+i") 新旧で同じワードを許容する
                      div.mb-1(:class="detail._areas[i].option.ignore_same_in_histories!==detail_original._areas[i].option.ignore_same_in_histories? 'changed':''")
                        input.form-check-input.mx-1(:id="'ignore_ignore_same_'+i", type="checkbox", v-model="detail._areas[i].option.ignore_same_in_histories", :disabled="!edit_mode")
                        label.ms-1.form-check-label(:for="'ignore_ignore_same_'+i") 最新{{ detail.setting.ignore_same_in_histories }}件の更新と同じものを除外する

                    td
                      div.mb-2(:class="JSON.stringify(detail._areas[i].option.ignore_words)!==JSON.stringify(detail_original._areas[i].option.ignore_words)? 'changed':''")
                        button.btn.btn-sm.btn-outline-primary.mb-1(type="button", @click="detail._areas[i].option.ignore_words.push('')", :disabled="!edit_mode") 除外ワード追加
                        div.input-group.input-group-sm.mb-1(v-for="w,k in detail._areas[i].option.ignore_words", :key="k")
                          input.form-control(type="text", v-model="detail._areas[i].option.ignore_words[k]", :disabled="!edit_mode")
                          button.btn.btn-outline-secondary(type="button", @click="detail._areas[i].option.ignore_words.splice(k,1)", :disabled="!edit_mode") X
                      div(:class="JSON.stringify(detail._areas[i].option.ignore_re_words)!==JSON.stringify(detail_original._areas[i].option.ignore_re_words)? 'changed':''")
                        button.btn.btn-sm.btn-outline-primary.mb-1(type="button", @click="detail._areas[i].option.ignore_re_words.push('')", :disabled="!edit_mode") 除外ワード(正規表現)追加
                        div.input-group.input-group-sm.re-input.mb-1(v-for="w,k in detail._areas[i].option.ignore_re_words", :key="k")
                          input.form-control(type="text", v-model="detail._areas[i].option.ignore_re_words[k]", :disabled="!edit_mode")
                          div.re-quote.start r"
                          div.re-quote.end "
                          button.btn.btn-outline-secondary(type="button", @click="detail._areas[i].option.ignore_re_words.splice(k,1)", :disabled="!edit_mode") X
          //- PreRule
          div.col-12.mt-3(:class="JSON.stringify(detail._pre_rule)!==JSON.stringify(detail_original._pre_rule)? 'changed':''")
            h6.text-muted.mb-0 PreConditions
            PreRulesCard(:rules="detail._pre_rule", :targets="targets", :master="true")
          //- Rule
          div.col-12.mt-3(:class="JSON.stringify(detail._rule)!==JSON.stringify(detail_original._rule)? 'changed':''")
            h6.text-muted.mb-0 Conditions
            RulesCard(:rules="detail._rule", :targets="targets", :master="true")

    //- Histories
    div.col-12.mt-4
      h5 チェック履歴 {{ all_loaded? '全':'最新1日分' }}{{ histories.length }}件
      button.btn.btn-primary.mx-2(type="button", @click="get_histories(false)") 1日分Load
      button.btn.btn-primary.mx-2(type="button", @click="get_histories(true)") 全件Load
      VueGoodTable.historiesv2(v-if="histories.length!=0", :columns="columns", :rows="histories", :pagination-options="pagination_options", styleClass="vgt-table condensed histories-table", :sort-options="{ enabled:true }", :row-style-class="rowStyleClassFn")
        //- Header
        template(slot="table-column", slot-scope="props")
          span
            | {{ props.column.label }}
        //- Row
        template(slot="table-row", slot-scope="props")
          span(v-if="props.column.field == 'd'")
            <svg v-if="props.row.d" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-12cac6b8="" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
          span(v-else-if="props.column.field == 'hc'")
            <svg v-if="props.row.hc" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-12cac6b8="" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
          span(v-else-if="props.column.field == 'nhc'")
            <svg v-if="props.row.nhc" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-12cac6b8="" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
          span(v-else-if="props.column.field == 'n'")
            <svg v-if="props.row.n" data-v-080e764a="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-12cac6b8="" class="feather feather-check-circle"><path data-v-080e764a="" d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline data-v-080e764a="" points="22 4 12 14.01 9 11.01"></polyline></svg>
          span(v-else-if="props.column.field == 'e'")
            <svg v-if="props.row.e" data-v-080e764a="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-12cac6b8="" class="feather feather-check-circle"><path data-v-080e764a="" d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline data-v-080e764a="" points="22 4 12 14.01 9 11.01"></polyline></svg>
          span(v-else-if="props.column.field == 'enf'")
            <svg v-if="props.row.enf" data-v-080e764a="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-12cac6b8="" class="feather feather-check-circle"><path data-v-080e764a="" d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline data-v-080e764a="" points="22 4 12 14.01 9 11.01"></polyline></svg>
          span(v-else-if="props.column.field == 'tb'")
            | {{ (props.row.tb / 1024).toLocaleString() }}kB
          div(v-else-if="props.column.field == 'other'")
            a.mx-2.pointer(@click="show_headers(props.row.id)") HEADERS
            a.mx-2.pointer(@click="show_info(props.row.id)") INFO
            a.mx-2.pointer(v-if="props.row.d", @click="show_diffs(props.row.id)") DIFFS
            a.mx-2.pointer(v-if="props.row.d", @click="show_line_diffs(props.row.id)") LINE_DIFFS
            a.mx-2(v-if="props.row.ss", style="cursor:pointer;", @click="preview(props.row.ss)") SS
          div(v-else-if="props.column.field == 'sources'")
            a.mx-2.pointer(@click="show_sources(props.row.id)") SOURCES
            a.mx-2.pointer(v-if="props.row.mhtml", :href='`/api/v2/history?mhtml&tid=${target_id}&id=${props.row.id}`') MHTML
            a.mx-2.pointer(v-if="props.row.full", :href='`/api/v2/history?full&tid=${target_id}&id=${props.row.id}`')  FULL
          span(v-else)
            | {{ props.formattedRow[props.column.field] }}
    //- PureDiff Modal
    div.col-12.text-start.d-none#history
      div.row.mt-2(v-for="area in diffs")
        div.col-12
          p.mb-1 {{ area_labels[area.id]? area_labels[area.id]:area.id }}
        div.col-12(v-if="area.news.length===0 && area.olds.length===0")
          p.my-0.ms-2.form-text このエリアの更新はありません
        div.col-12.col-sm-6(v-if="area.news.length")
          div.diff-box.new
            p.mb-0(v-for="n in area.news") {{ n }}
        div.col-12.col-sm-6(v-if="area.olds.length")
          div.diff-box.old
            p.mb-0(v-for="o in area.olds") {{ o }}

    //- LineDiff Modal
    div.modal.fade#line-diffs-modal(tabindex="-1", aria-hidden="true")
      div.modal-dialog.modal-full.modal-dialog-scrollable
        div.modal-content
          div.modal-body
            div.row.mt-2(v-for="(html, area_id) in line_diffs")
              div.col-12
                p.mb-1 {{ area_labels[area_id]? area_labels[area_id]:area_id }}
              div.col-12
                div(v-html="html")
          div.modal-footer
            button.btn.btn-secondary(type="button", data-bs-dismiss="modal") Close

    //- SourcesModal
    div.modal.fade#sourcesModal(tabindex="-1", aria-hidden="true")
      div.modal-dialog.modal-full.modal-dialog-scrollable
        div.modal-content
          div.modal-body
            ul.nav.nav-tabs#sources-tabs(role="tablist")
              li.nav-item(role="presentation")
                button.nav-link.active#preview-tab(data-bs-toggle="tab", data-bs-target="#preview", type="button", role="tab", aria-controls="preview", aria-selected="true") Preview
              li.nav-item(role="presentation")
                button.nav-link#raw-tab(data-bs-toggle="tab", data-bs-target="#raw", type="button", role="tab", aria-controls="raw", aria-selected="false") Raw
            div.tab-content#sources-tab-content.pt-3
              div.tab-pane.fade.show.active#preview(role="tabpanel", aria-labelledby="home-tab")
                div.mb-3(v-for="lines,area_id in sources.preview", :key="area_id")
                  label.form-label {{ area_id }}
                  div.content-preview
                    p(v-for="e,i in lines", v-html="e")
              div.tab-pane.fade#raw(role="tabpanel", aria-labelledby="profile-tab")
                div.mb-3(v-for="source,area_id in sources.raw", :key="area_id")
                  label.form-label {{ area_id }}
                  pre(v-highlightjs="source")
                    code.html
          div.modal-footer
            button.btn.btn-secondary(type="button", data-bs-dismiss="modal") Close
  

</template>

<script>
import { Modal,Tab } from 'bootstrap';
import RulesCard from '@/components/RulesCard.vue';
import PreRulesCard from '@/components/PreRulesCard.vue';
import moment from 'moment';
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import 'viewerjs/dist/viewer.css'
import { api as viewerApi } from "v-viewer"

export default {
  name:"TargetV2",
  components:{
    PreRulesCard, RulesCard, VueGoodTable,
  },
  head:{
    title (){ return {inner:`TargetV2:${this.$route.query.id}`} }
  },
  data (){
    return {
      edit_mode:true,
      target_id:0,
      detail:{ user_id:0, areas:[] },
      detail_original:{},
      site_types:[
        "Static",
        "Dynamic",
        "Protected",
        "RSS",
        "V2_Headless",
        "V2_Normal"
      ],
      trigger_types:[ "","Interval", "Cron" ],
      target_path_types:[ "SELECTOR", "XPATH" ],
      histories:[],
      history_div:null,
      APP_BASE_URL:process.env.VUE_APP_BASE_URL,
      diffs:[],
      line_diffs:[],
      viewer_option:{
        // navbar:false,
        title:false,
        // toolbar:false,
        rotatable:false,
        scalable:false,
        transition:false,
      },
      pagination_options: {
        enabled: true,
        mode: 'pages',
        position: 'both',
        perPage: 144,
        perPageDropdown: [24, 144, 1440]
      },
      columns: [
        {
          label: 'ID',
          field: 'id',
          width: '100px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true },
        },
        {
          label: 'Checked',
          field: 'c',
          width: '120px',
          thClass: 'text-center font-sm',
          tdClass: 'text-center',
          type: 'date',
          dateInputFormat: 't', // timestamp
          dateOutputFormat: 'LL/dd HH:mm', // outputs Mar 16th 2018
          sortable: false
        },
        {
          label: '更新',
          field: 'd',
          width: '75px',
          thClass: 'text-center font-sm',
          tdClass: 'text-center',
          filterOptions: { enabled: true, placeholder:" ", filterDropdownItems: [ { value: true, text: 'あり' }, { value: false, text: 'なし' } ]},
          sortable: false
        },
        {
          label: '通知',
          field: 'n',
          width: '75px',
          thClass: 'text-center font-sm',
          tdClass: 'text-center',
          filterOptions: { enabled: true, placeholder:" ", filterDropdownItems: [ { value: true, text: 'あり' }, { value: false, text: 'なし' } ]},
          sortable: false
        },
        {
          label: 'エラー',
          field: 'e',
          width: '75px',
          thClass: 'text-center font-sm',
          tdClass: 'text-center',
          filterOptions: { enabled: true, placeholder:" ", filterDropdownItems: [ { value: true, text: 'あり' }, { value: false, text: 'なし' } ]},
          sortable: false
        },
        {
          label: 'No\nElement',
          field: 'enf',
          width: '75px',
          thClass: 'text-center font-sm',
          tdClass: 'text-center',
          filterOptions: { enabled: true, placeholder:" ", filterDropdownItems: [ { value: true, text: 'あり' }, { value: false, text: 'なし' } ]},
          sortable: false
        },
        {
          label: 'Status',
          field: 's',
          width: '90px',
          thClass: 'text-center font-sm',
          tdClass: 'text-center',
          filterOptions: { enabled: true },
          sortable: false
        },
        {
          label: 'VS',
          field: 'v',
          width: '120px',
          thClass: 'text-center font-sm',
          tdClass: 'text-center',
          type: 'date',
          dateInputFormat: 't', // timestamp
          dateOutputFormat: 'LL/dd HH:mm', // outputs Mar 16th 2018
          sortable: false
        },
        {
          label: 'Time',
          field: 't',
          width: '90px',
          thClass: 'text-center font-sm',
          tdClass: 'text-center',
          sortable: false
        },
        {
          label: '',
          field: 'other',
          sortable: false
        },
        {
          label: '',
          field: 'sources',
          sortable: false
        },
        {
          label: 'Node',
          field: 'node',
          width: '75px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false
        },
        {
          label: 'Proxy',
          field: 'p',
          width: '90px',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false
        },
        {
          label: 'HTML\nDiff',
          field: 'hc',
          width: '75px',
          thClass: 'text-center font-sm',
          tdClass: 'text-center',
          filterOptions: { enabled: true, placeholder:" ", filterDropdownItems: [ { value: true, text: 'あり' }, { value: false, text: 'なし' } ]},
          sortable: false
        },
        {
          label: 'Text\nDiff',
          field: 'nhc',
          width: '75px',
          thClass: 'text-center font-sm',
          tdClass: 'text-center',
          filterOptions: { enabled: true, placeholder:" ", filterDropdownItems: [ { value: true, text: 'あり' }, { value: false, text: 'なし' } ]},
          sortable: false
        },
        {
          label: 'Bytes',
          field: 'tb',
          width: '75px',
          thClass: 'text-center font-sm',
          tdClass: 'text-center',
          filterOptions: { enabled: true, placeholder:" "},
          sortable: false
        },
      ],
      all_loaded:false,
      sources:{},
      sources_modal:null,
      line_diffs_modal:null,
      showed_history_id:null,
      showed_history_id2:null,
    }
  },
  computed: {
    targets (){
      return this.detail._areas.map(e=>{ return { content:{dels:[],lines:[]}, id:e.id, label:e.label }; });
    },
    area_labels (){
      return this.detail._areas.reduce((r,e)=>{ r[e.id]=e.label; return r; },{});
    }
  },
  async mounted (){
    this.target_id = Number(this.$route.query.id);
    this.history_div = document.getElementById("history");
    this.history_div.remove();
    this.history_div.classList.remove("d-none");
    this.get()
    // this.get_histories();
    this.sources_modal = new Modal(document.getElementById("sourcesModal"));
    this.line_diffs_modal = new Modal(document.getElementById("line-diffs-modal"));
  },
  methods: {
    loading (mes=""){
      let spinner = document.createElement("div");
      spinner.classList.add("spinner-border", "text-secondary");
      let p = document.createElement("p");
      p.innerText = mes;
      let div = document.createElement("div");
      div.appendChild(spinner);
      div.appendChild(p);
      this.$swal({ content:div, button:false });
    },
    async get(){
      this.loading();
      let res = await this.axios.get("/api/v2/target?id="+this.target_id);
      this.detail = res.data;
      this.detail_original = JSON.parse(JSON.stringify(this.detail));
      this.$swal.close();
    },
    async get_histories (all=false){
      this.loading();
      try{
        let res = await this.axios.get("/api/v2/histories?id="+this.target_id+(all? '&all':''));
        let histories = res.data;
        histories.sort((a,b)=>b.checked-a.checked);
        this.histories = histories;
        if (all)
          this.all_loaded = true;
        this.$swal.close();
      }
      catch(e){
        console.log(e);
        console.log(e.response);
        this.$swal("","","error");
      }
    },
    async show_diffs (id){
      this.showed_history_id2 = this.showed_history_id;
      this.showed_history_id = id;
      let res = await this.axios.get("/api/v2/history?diffs&id="+id+"&tid="+this.target_id);
      this.diffs = res.data;
      this.$swal({ content:this.history_div, buttons:[false, "close"] });
    },
    async show_line_diffs (id){
      this.loading();
      this.showed_history_id2 = this.showed_history_id;
      this.showed_history_id = id;
      let res = await this.axios.get("/api/v2/history?line_diffs&id="+id+"&tid="+this.target_id);
      this.line_diffs = res.data;
      this.line_diffs_modal.show();
      this.$swal.close();
    },
    async show_headers (id){
      this.showed_history_id2 = this.showed_history_id;
      this.showed_history_id = id;
      let res = await this.axios.get("/api/v2/history?headers&id="+id+"&tid="+this.target_id);
      this.$swal(JSON.stringify(res.data, null, 2), { buttons:[false, "close"] });
    },
    async show_info (id){
      this.showed_history_id2 = this.showed_history_id;
      this.showed_history_id = id;
      let res = await this.axios.get("/api/v2/history?info&id="+id+"&tid="+this.target_id);
      this.$swal(JSON.stringify(res.data, null, 2), { buttons:[false, "close"] });
    },
    async show_sources (id){
      this.showed_history_id2 = this.showed_history_id;
      this.showed_history_id = id;
      this.loading();
      this.sources = {};
      let res = await this.axios.get("/api/v2/history?sources&id="+id+"&tid="+this.target_id);
      this.sources = res.data;
      this.sources_modal.show();
      this.$swal.close();
    },
    rowStyleClassFn(row) {
      if (row.id==this.showed_history_id)
        return "showed_row";
      if (row.id==this.showed_history_id2)
        return "showed_row2";
      if (row.d && row.e)
        return 'changed-error-row';
      else if (row.d)
        return 'changed-row';
      else if (row.e)
        return 'error-row';
      else
        return 'normal-row';
    },
    async pause(){
      try{
        this.loading();
        await this.axios.post("/api/v2/pause", { target_v2_id:this.target_id, user_id:this.detail.user_id });
        this.detail.valid = false;
        this.detail.node_id = null;
        this.$swal("Paused!","","success");
      }
      catch(err){
        console.log({ err });
        await this.$swal("Error","","error");
      }
    },
    async resume(){
      try{
        this.loading();
        let res = await this.axios.post("/api/v2/resume", { target_v2_id:this.target_id, user_id:this.detail.user_id });
        this.detail.valid = true;
        // this.detail.node_id = res.data.node_id;
        // this.detail.timing = res.data.timing;
        // this.detail.job_start_at = res.data.job_start_at;
        // this.detail_original = JSON.parse(JSON.stringify(this.detail));
        this.$swal("Resumed!","","success");
      }
      catch(err){
        console.log({ err });
        await this.$swal("Error","","error");
      }
    },
    async resume_manual(){
      let val = await this.$swal({text:"Input NodeID", content:"input", buttons:[true,true]});
      try {
        var node_id = Number(val)
      }
      catch(err){
        await this.$swal("Error","Invalid NodeID","error");
        return;
      }

      try{
        this.loading();
        let res = await this.axios.post("/api/v2/resume", { target_v2_id:this.target_id, node_id:node_id, user_id:this.detail.user_id });
        this.detail.valid = true;
        // this.detail.node_id = res.data.node_id;
        // this.detail.timing = res.data.timing;
        // this.detail.job_start_at = res.data.job_start_at;
        // this.detail_original = JSON.parse(JSON.stringify(this.detail));
        this.$swal("Resumed!","","success");
      }
      catch(err){
        console.log({ err });
        await this.$swal("Error","","error");
      }
    },
    async migrate(){
      let val = await this.$swal({text:"Input GroupID", content:"input", buttons:[true,true]});
      try {
        var group_id = Number(val)
      }
      catch(err){
        await this.$swal("Error","Invalid GroupID","error");
        return;
      }

      try{
        this.loading();
        let res = await this.axios.post("/api/v2/migrate", { target_v2_id:this.target_id, target_group_id: group_id, user_id:this.detail.user_id });
        this.$swal("Group Migrated!","","success");
      }
      catch(err){
        console.log({ err });
        await this.$swal("Error","","error");
      }
    },
    async post(){
      // let val = await this.$swal({ title:"Are you sure?", dangerMode:true, buttons:[true,true] });
      // if(!val)
      //   return;
      try {
        this.loading();
        await this.axios.post("/api/v2/target", this.detail);
        this.detail_original = JSON.parse(JSON.stringify(this.detail));
        this.edit_mode = false;
        this.$swal.close();
      }
      catch(err){
        this.$swal("Error","","error");
        console.log({err});
      }
    },
    async change_monitor(){
      let val = await this.$swal({text:"Input Monitor ID", content:"input", dangerMode:true, buttons:[true,true]});
      try {
        var node_id = Number(val)
      }
      catch(err){
        await this.$swal("Error","Invalid NodeID","error");
        return;
      }
      try{
        this.loading();
        let res = await this.axios.post("/api/v2/restart", { target_v2_id:this.target_id, node_id:node_id });
        this.$swal("Monitor Change Queued!","","success");
      }
      catch(err){
        console.log({ err });
        await this.$swal("Error","","error");
      }
    },
    async restart(){
      try{
        this.loading();
        let res = await this.axios.post("/api/v2/restart", { target_v2_id:this.target_id });
        this.$swal("Monitor Change Queued!","","success");
      }
      catch(err){
        console.log({ err });
        await this.$swal("Error","","error");
      }
    },
    copy (string) {
      var tmp = document.createElement("div");
      var pre = document.createElement('pre');
      pre.style.webkitUserSelect = 'auto';
      pre.style.userSelect = 'auto';
      tmp.appendChild(pre).textContent = string;
      var s = tmp.style;
      s.position = 'fixed';
      s.right = '200%';
      document.body.appendChild(tmp);
      document.getSelection().selectAllChildren(tmp);
      var result = document.execCommand("copy");
      document.body.removeChild(tmp);
      return result;
    },
    preview (ss_name){
      const $viewer = viewerApi({
        options:this.viewer_option,
        images: [ `${this.APP_BASE_URL}/screenshot/${ss_name}-new.jpg`,`${this.APP_BASE_URL}/screenshot/${ss_name}-old.jpg` ]
      });
    }
  },
  filters: {
    moment (timestamp) {
      if (timestamp)
        return moment(new Date(timestamp*1000)).format('YYYY/MM/DD HH:mm');
      else
        return "";
    }
  }
}
</script>

<style lang="scss" scoped>
.setting-editor ::v-deep {
  .changed {
    background-color: #ffff99!important;
  }

  input[type="checkbox"]{
    transform: scale(1.5);
  }

  .re-input {
    input {
      padding-left: 25px!important;
      padding-right: 25px!important;
    }
    .re-quote {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1001;
      color: #c5c5c5;
      &.start {
        left: 15px;
      }
      &.end {
        right: 35px;
      }
    }
  }
}

th {
  vertical-align: middle;
}

.targetarea-table, .action-table {
  vertical-align: middle;
}

.diff-box {
  outline: solid 1px darkgray;
  padding-left: .5rem;
  font-size: 14px;
  &.new {
    border-left: solid 10px yellow;
  }
  &.old {
    border-left: solid 10px lightcoral;
  }
}

#sourcesModal {
  textarea {
    font-size: 14px;
  }
}

.modal-full {
  max-width: 95%;
}

.content-preview {
  width: 100%;
  // height: 250px;
  padding: 8px;
  overflow: auto;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .2rem;
  p ::v-deep {
    margin-bottom: 0px;
    font-size: .875em;
    del {
      background-color: #fbb;
      color: #555;
    }
  }
}

.area-option {
  font-size: 12px;
  input[type="checkbox"]{
    transform: scale(1.25);
  }
}

.historiesv2 ::v-deep {
  font-size: 14px!important;
  td {
    padding: .2em .4em .2em .4em!important;
    .pointer {
      cursor: pointer;
    }
  }

  .normal-row:hover {
    background-color: rgba(0, 0, 0, 0.075)!important;
  }
  .changed-error-row {
    background-color: #d1e7dd;
    background-image: -webkit-gradient(linear, 0 0, 100% 100%,color-stop(.25, #f8d7da), color-stop(.25, transparent),color-stop(.5, transparent), color-stop(.5, #f8d7da),color-stop(.75, #f8d7da), color-stop(.75, transparent),to(transparent));
    background-size: 14px 14px;
    &:hover {
      background-color: #c1d6cc!important;
      background-image: -webkit-gradient(linear, 0 0, 100% 100%,color-stop(.25, #e5c7ca), color-stop(.25, transparent),color-stop(.5, transparent), color-stop(.5, #e5c7ca),color-stop(.75, #e5c7ca), color-stop(.75, transparent),to(transparent))!important;
      background-size: 14px 14px;
    }
    td {
      border-bottom:  1px solid #bcd0c7!important;
    }
  }
  .changed-row {
    background-color: #d1e7dd;
    &:hover{
      background-color: #c1d6cc!important;
    }
    td {
      border-bottom:  1px solid #bcd0c7!important;
    }
  }
  .error-row {
    background-color: #f8d7da;
    &:hover{
      background-color: #e5c7ca!important;
    }
    td {
      border-bottom:  1px solid #dfc2c4!important;
    }
  }
  tr:hover{
    background-color: #bfe2e9!important;
  }
  .showed_row {
    // background-color: #cfe2ff!important;
    font-weight: 900;
  }
  .showed_row2 {
    // background-color: #bacbe6!important;
    font-weight: 600;
  }
  .feather {
    width: 18px;
    height: 18px;
    vertical-align: text-bottom;
    margin-top: 1px;
  }
  select.vgt-select {
    padding: 6px 1px;
  }
  th {
    font-size: 12px;
    vertical-align: middle;
  }
}

</style>